.bg-dark {
  background-color: #2e2e2e !important;
}
.card {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.45), 0 7px 10px -10px rgba(0, 0, 0, 0.55);
}
.text-dark-gray {
  color: darkgray;
}

.text-dark-gray .card-title {
  color: whitesmoke;
}

.text-whitesomke {
  color: whitesmoke;
}
